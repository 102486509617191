<template>
  <AuthLayout>
    <template #default>
      <router-view />
    </template>
  </AuthLayout>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout.vue';

export default {
  components: {
    AuthLayout,
  },
};
</script>