<template>
  <div class="w-full h-screen flex flex-col items-center justify-around">
    <div class="text-blue-600 text-3xl">
      FEXPLOİT
    </div>

    <slot />

    <footer class="text-gray-400 justify-self-end">
      ©2001–2019 All Rights Reserved. Treva®
    </footer>
  </div>
</template>